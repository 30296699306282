import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import WebriqForm from "@webriq/gatsby-webriq-form"
import { Envelope } from "react-bootstrap-icons"

export default function ContactSection() {
  return (
    <section className="contact-section" style={{ paddingTop: "200px" }}>
      <div id="contact" />
      <Container>
        <Row>
          <Col lg="6" className="mx-auto">
            {" "}
            <div className="contact-form text-center">
              <h2 className="my-0">Message Us</h2>
              <p className="mb-5">
                To request a price quote or
                <br />
                additional Information
              </p>
              <WebriqForm
                name="Pricing Form"
                data-form-id="87b6c2d3-5b49-4d37-a283-f650a2fde220"
                className="form-newsletter"
                method="POST"
                data-thankyou-url="/thank-you-quote"
              >
                <div className="form-row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="Company Name"
                        type="text"
                        id="companyName"
                        required
                        placeholder="Company Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="Email"
                        type="text"
                        id="email"
                        required
                        placeholder="Email"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="Phone Number"
                        id="phone"
                        type="tel"
                        placeholder="Phone (optional)"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <textarea
                    className="form-control h-auto"
                    name="Message"
                    id="message"
                    required
                    rows="4"
                    cols="50"
                    placeholder="Message"
                  />
                </div>
                <div className="form-group">
                  <div className="webriq-recaptcha" />
                </div>
                <div className="form-group mb-0">
                  <button className="btn btn-alt w-100" type="submit">
                    <Envelope
                      className="mr-2"
                      style={{ marginBottom: "2px" }}
                    />
                    Send Message
                  </button>
                </div>
              </WebriqForm>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
