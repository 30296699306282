import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlockContent from "@sanity/block-content-to-react"
import ContactSection from "@components/sections/contact-us"

const serializers = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const ContactPage = ({ data }) => {
  const page = data && data.page

  return (
    <Layout>
      <SEO title={page.title} />
      <ContactSection />
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
      id
      title
      _rawBody
    }
  }
`

export default ContactPage
